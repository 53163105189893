const bottomNavList = [
  {
    name: "Schools",
    route: "schools",
    icon: "mdi-school",
    perimission: ["SUP"],
  },
  {
    name: "Syllabus",
    route: "syllabus",
    icon: "mdi-library",
    perimission: ["SUP", "ADM", "EMP"],
  },
  {
    name: "Staff",
    route: "staff",
    icon: "mdi-list-box",
    perimission: ["ADM", "EMP"],
  },
  {
    name: "Planner",
    route: "planner",
    icon: "mdi-notebook",
    perimission: ["ADM", "EMP"],
  },
  {
    name: "Update",
    route: "update",
    icon: "mdi-book-open",
    perimission: ["ADM", "EMP"],
  },
  {
    name: "Message",
    route: "message",
    icon: "mdi-message",
    perimission: ["ADM", "EMP"],
  },
  {
    name: "Back",
    route: "/dashboard",
    icon: "mdi-arrow-left-circle",
    perimission: ["ADM", "EMP"],
  },
];
export default bottomNavList;
